<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div
    class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center"
    id="page-login"
  >
    <div class="">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class=" no-gutter justify-center items-center">
            <div class="  d-theme-dark-bg">
              <div class="p-8 login-tabs-container">
                <div class="vx-card__title  justify-center item-center">
                  <p></p>
                </div>
                <vs-divider>ورود</vs-divider>

                <div class="my-2">
                  <vs-input
                    name="email"
                    icon-no-border
                    icon="icon icon-user "
                    icon-pack="feather"
                    label-placeholder="Email"
                    v-model="email"
                    dir="ltr"
                    class="w-full"
                  />

                  <vs-input
                    @keyup.enter="login"
                    type="password"
                    name="password"
                    icon-no-border
                    icon="icon icon-lock"
                    icon-pack="feather"
                    label-placeholder="Password"
                    v-model="password"
                    dir="ltr"
                    class="w-full mt-6"
                  />

                  <div class="flex flex-wrap justify-between my-5">
                    <!-- <vs-checkbox v-model="checkbox_remember_me" class="mb-3">Remember Me</vs-checkbox> -->
                    <!-- <router-link to="">Forgot Password?</router-link> -->
                  </div>

                  <vs-button class="w-full" @click="login">Login</vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      checkbox_remember_me: false
    };
  },
  methods: {
    login() {
      this.$vs.loading();

      const payload = {
        checkbox_remember_me: this.checkbox_remember_me,
        userDetails: {
          email: this.email,
          password: this.password
        }
      };
      // this.$vs.loading()
      // console.log(payload);
      this.$store
        .dispatch("auth/loginJWT", payload)
        .then(response => {
          // console.log(response);
          const header = localStorage.getItem("accessToken");
          this.$store.dispatch("auth/fetchUserData", header);
          if (this.user.userRole.includes("Admin")) {
            this.$router.push({ path: "/home" }).catch(() => {});
            this.$vs.loading.close();
            this.$vs.notify({
              title: "ورود",
              text: response.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success"
            });
          } else {
            console.error(
              `User has no privilege to access this route\nYour current privileges:\n${this.user.userRole}`
            );
            this.$vs.notify({
              title: "Access denied",
              text: "کاربر اجازه دسترسی ندارد",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "#f00"
            });
            this.$vs.loading.close();
          }
        })
        .catch(err => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: err.response.data.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        });
    }
  },
  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    }
  },
  created() {}
};
</script>
